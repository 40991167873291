<template>
  <div class="navbar-container d-flex content align-items-center">

    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link
          class="nav-link"
          @click="toggleVerticalMenuActive"
        >
          <feather-icon
            icon="MenuIcon"
            size="21"
          />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <b-navbar-nav class="nav align-items-center ml-auto">
      <!-- <dark-Toggler class="d-none d-lg-block" /> commentato da Simone il 17/01/23 per pulizia -->
      <!-- <search-bar /> commentato da Simone il 17/01/23 per pulizia -->
      <user-dropdown />
    </b-navbar-nav>
  </div>
</template>

<script>
import {
  BLink, BNavbarNav,
} from 'bootstrap-vue'
//import SearchBar from './components/SearchBar.vue' commentato da Simone il 17/01/23 per pulizia
//import DarkToggler from './components/DarkToggler.vue' commentato da Simone il 17/01/23 per pulizia
import UserDropdown from './components/UserDropdown.vue'

export default {
  components: {
    BLink,

    // Navbar Components
    BNavbarNav,
    //SearchBar, commentato da Simone il 17/01/23 per pulizia
    //DarkToggler, commentato da Simone il 17/01/23 per pulizia
    UserDropdown,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
}
</script>
